import './App.css';
import Moviecard from './moviecar.js';
import ColorSchemesExample from './Navbar.js'
function App() {
  return (
    <>
    <ColorSchemesExample/> 
    <Moviecard />
    </>
  );
}

export default App;
