
import Image from './IMDB_Logo_2016.svg.png'
function ColorSchemesExample() {
  return (
    <>
     <nav class="bg-black border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
  <div class="container flex justify-center ">
    <a href="https://flowbite.com/" class=" ml-30">
        <img src={Image} class="mr-3 h-7 sm:h-10  mx-auto " alt="Flowbite Logo" />
    </a>
   
   
  </div>
</nav>
      
    </>
  );
}

export default ColorSchemesExample;